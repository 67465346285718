import React, { useState, useEffect, useRef } from 'react';

const HeroSection = () => {
  const [isAtTop, setIsAtTop] = useState(true);
  const anchorRef = useRef(null); // Erstelle ein Ref für den Ankerpunkt

  // Scroll-Event behandeln
  useEffect(() => {
    const handleScroll = () => {
      setIsAtTop(window.scrollY === 0);
    };

    window.addEventListener('scroll', handleScroll);
    
    // Bereinigen des Event-Listeners
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Funktion zum Scrollen
  const scrollToAnchor = () => {
    window.scrollBy({ 
      top: window.innerHeight * 0.9, // 90% der Bildschirmhöhe nach unten scrollen
      behavior: 'smooth' // Sanftes Scrollen
    });
  };

  return (
    <div className="relative w-full h-[90vh] overflow-hidden">
      {/* Desktop-Video (Querformat) */}
      <div id="video-container">
        <video 
          src='/videos/VideoMomente.mp4' 
          autoPlay 
          loop 
          muted 
          playsInline
          poster="/images/Hero1.PNG" // Standbild, das angezeigt wird, während das Video geladen wird
          className="absolute inset-0 w-full h-full object-cover hidden md:block" // Verstecke auf mobilen Geräten
        />
        
        {/* Mobile-Video (Hochformat) */}
        <video 
          src='/videos/VideoMomenteQuer.mp4' 
          autoPlay 
          loop 
          muted 
          playsInline
          poster="/images/Hero2.PNG" // Standbild für mobile Geräte
          className="absolute inset-0 w-full h-full object-cover md:hidden" // Zeige nur auf mobilen Geräten
        />
      </div>
      
      {/* Textüberlagerung */}
      <div className="absolute inset-0 flex flex-col justify-center items-center z-10 text-center bg-gray-200 bg-opacity-25 p-4">
        <h1 className="text-gray-900 text-4xl font-bold">Momente</h1>
        <p className="text-gray-900 text-lg mt-2 font-bold">nemis tili markazi</p>
        <p className="text-gray-900 text-sm mt-2 font-bold">by IJP</p>
      </div>

      {/* Abwärtspfeil */}
      {isAtTop && (
        <div className="absolute bottom-10 right-10 cursor-pointer z-20 flex items-center justify-center w-16 h-16 bg-gray-200 bg-opacity-50 rounded-full" onClick={scrollToAnchor}>
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className="h-8 w-8 text-gray-900" 
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m0 0l-4-4m4 4l4-4" />
          </svg>
        </div>
      )}

      {/* Ankerpunkt */}
      <div ref={anchorRef} className="h-screen bg-gray-100 flex items-center justify-center">
        <h2 className="text-2xl font-bold">Willkommen im Video-Bereich</h2>
        {/* Zusätzliche Inhalte können hier hinzugefügt werden */}
      </div>
    </div>
  );
};

export default HeroSection;
